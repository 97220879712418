<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <div>
                <h4>PO Details</h4>
                <h6>PO No: {{ purchase_order.po_number }}</h6>
                <h6>PO Date: {{ purchase_order.po_date }}</h6>
                <h6>Supplier Name: {{ purchase_order.contact_profile?.full_name }}</h6>
            </div>
        </div>
        <ListTable
                :list="ledgerGeneralList"
                :grn_numbers="getGrnNumbers"
                @selectGrnNumber="selectGrnNumber"
        />
    </div>
  <!--    GRN list-->
    <GrnListTable v-for="(grn_master_list, index) in grnMasterLists"
                  :index="index"
                  :grn_master_list="grn_master_list"
                  :account_payable="accountPayable"
                  @storeLandedCostingData="storeLandedCostingData"
    />

    <div v-if="!grnMasterLists.length" style="min-height: calc(100vh - 158px);" class="card">
        <div class="card p-2" style="min-height: 200px">
            <p class="text-center mt-5">No GRN data</p>
        </div>
    </div>

    <GlobalLoader/>
</template>

<script setup>
import ListTable from '@/components/molecule/scm/landed-costing/LandedCostingAddTable.vue'
import GrnListTable from '@/components/molecule/scm/landed-costing/GrnListTable.vue'
import {computed, inject, onMounted, ref} from 'vue';
import {useRoute} from "vue-router";
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleLandedCosting from "@/services/modules/scm/landed-costing";
import handlePurchase from "@/services/modules/purchase";
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const route = useRoute()
const loading = ref(false);

const {fetchSinglePO} = handleRequisitions();
const {fetchLGLandedCosting, fetchGrnLandedCosting, storeLandedCosting} = handleLandedCosting();
const {fetchAccountPayable} = handlePurchase()
let ledgerGeneralList = ref([]);
let grnMasterLists = ref([]);
let purchase_order = ref({});
let accountPayable = ref([])
let companyId = computed(() => route.params.companyId);
const loader = ref(false);
let prefix = ref('')
let serial = ref('')

// on select grn number then push and remove lg amounts
const selectGrnNumber = (lg_id, amount, account_head_id, e) => {
    //Removed already exits ledger generals id and amount
    grnMasterLists.value.forEach((item) => {
        const index = item.amounts.findIndex(obj => obj.id === lg_id);
        if (index !== -1) {
            item.amounts.splice(index, 1);
        }
    })

    const getGrn = grnMasterLists.value.find(grn_number => grn_number.id == e.target.value)
    if (getGrn != undefined) {
        const getLedgerGeneral = getGrn.amounts.find(amount => amount.id == lg_id)
        if (getLedgerGeneral == undefined) {
            getGrn.amounts.push({id: lg_id, amount, account_head_id})
        }
    }

}

const getGrnNumbers = computed(() => {
    return grnMasterLists.value.map(function (item) {
        return {
            id: item.id,
            grn_number: item.grn_number
        }
    })
})

function getCompanyQuery() {
    return `?company_id=${companyId.value}`;
}

function getQuery() {
    let query = getCompanyQuery()
    query += '&txn_no=' + purchase_order.value.po_number
    return query
}

async function getLandedCostinList() {
    try {
        const res = await fetchLGLandedCosting(getQuery());
        if (res.status) {
            ledgerGeneralList.value = res.data;
        } else {
            ledgerGeneralList.value = []
        }
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

async function getGrnLandedCostinList() {
    try {
        const res = await fetchGrnLandedCosting(getQuery());
        if (res.status) {
            grnMasterLists.value = res.data;
        } else {
            grnMasterLists.value = []
        }
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

async function getPurchaseOrder(id) {
    try {
        const res = await fetchSinglePO(id, getCompanyQuery());
        if (res.status) {
            purchase_order.value = res.data;
        }
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

async function storeLandedCostingData(grnMaster, totalLGAmount){
    try {
        grnMaster.total_lg_amount = totalLGAmount
        grnMaster.bill_number = prefix.value + '-' + serial.value
        grnMaster.purchase_order_master_id = purchase_order.value.id
        let res = await storeLandedCosting(grnMaster, getCompanyQuery());
        if(res.status) {
            showSuccess(res.message)
            await getListAndSetData();
        }
        if(!res.status) {
            showError(res.message)
        }
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

async function getAccountPayable() {
    try {
        const res = await fetchAccountPayable(getCompanyQuery());
        if (res.status) {
            accountPayable.value = res.data;
        }
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

async function getListAndSetData(){
    let voucher = await generateTxnNumber(`?company_id=${companyId.value}&voucher_type=journal_voucher&txn_type=grn_journal`);
    prefix.value = voucher.prefix;
    serial.value = voucher.serial;
    await getLandedCostinList();
    await getGrnLandedCostinList();

    if (grnMasterLists.value.length > 0) {
        let firstGrnMaster =  grnMasterLists.value[0]
        firstGrnMaster.date =  new Date().toISOString().split('T')[0]
        firstGrnMaster.prefix = prefix.value
        firstGrnMaster.serial = serial.value
        if (accountPayable.value?.length) {
            firstGrnMaster.account_payable_head_id = accountPayable.value[0].id
        }

        firstGrnMaster.amounts = ledgerGeneralList.value.filter(item => item.grn_masters.length == 0).map(function (item) {
            return {id: item.id, amount: item.amount, account_head_id: item.account_head_id}
        });
    }
}

onMounted(async () => {
    if (route.query.purchase_order_id) {
        await getPurchaseOrder(route.query.purchase_order_id);
    }
    await getAccountPayable();
    await getListAndSetData();

})
</script>
